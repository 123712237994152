import { BaseHandler } from './base-handler.js';
import { MapHandler } from './map-handler.js';
import Cookies from 'js-cookie';
//import Bowser  from "bowser";
const localize = require('./localize.js');
var geoDebug = false;

export const Discount = {
  //Discount location autocomplete
  Key : {
    LocationCookie : "cf_discount",
    LocationDataLatLng : "data-latlng",
    LocationDataLocationType : "data-location-type", //1: current, 2: autocomplete, 3: geo, 4: base
    LocationDataToteDistance : "data-distance",
    LocationDataAddress : "data-address",
    LocationUseCurrent : localize.get("discount.filter.location.usecurrentlocation"),
    LocationUseGeo : localize.get("discount.filter.location.usegeolocation"),
    LocationUseBase : localize.get("discount.filter.location.usebaselocation"),
    LocationUnknown : localize.get("discount.filter.location.unknownlocation"),
    ToteDistance1000 : localize.get("discount.tote.distance.morethan1000"),
    ToteDistanceLessThan1 : localize.get("discount.tote.distance.lessthan1"),
    LocationDeniedError : localize.get("discount.error.locationdenied"),
    AutoCompleteGeneralLocation : localize.get("discount.autocomplete.selectgenerallocation"),
    AutoCompleteBaseLocation : localize.get("discount.autocomplete.selectnolocation"),
    AutoCompleteUnknownLocation : localize.get("discount.autocomplete.selectunknownlocation"),
    DistanceMax : BaseHandler.Setting.DistanceMax
  },
  InitDiscountFilter : ($keyword, $type, location, $cat, $action, _callback) => {
    window.baseInfo = {};
    //if location field is not set, set default
    //const coord = $(location.input).attr(Discount.Key.LocationDataLatLng);
    const loc = Discount.GetDefaultLocation(location.input);console.log("Loc type is:")
      console.log(loc);
    if (typeof loc === "undefined") {console.log("Getting base info...")
      window.basestatus = "init";

      const timer = setInterval(()=> {
        if (window.basestatus === "ready") {
          clearTimeout(timer);
          Discount.SetLocationField(location.input,window.baseInfo);
        }
      },100);
    } else {console.log(loc);console.log("-*******");
      Discount.SetLocationField(location.input,loc);
    }

    //Action click => callback action
    $action.on("click",() => {
      _callback($keyword, $type, location.input, $cat, $action);
    });
    //Keywored keypress (enter) => callback action
    $keyword.on("keyup",(e) => {console.log(e.keyCode);
      if (e.key === "Enter" || e.keyCode === 13) {
        $action.trigger("click");
      }
    });
  },
  EmbedMap : ($tgt,id) => {
    let dispMap = false;
    let coord = $tgt.attr(Discount.Key.LocationDataLatLng);

    // window.nomapMsg = $tgt.find("p").html();
    // $tgt.find("p").remove();console.log(window.nomapMsg);

    //if address doesn't exists, hide
    let address = $tgt.attr(Discount.Key.LocationDataAddress).trim();
    if (address) {
      let mapdata = {
        q: address //address
      };
      mapdata = coord && coord !== "," ? Object.assign({"center": coord.trim() }, mapdata) : mapdata;
      mapdata = Object.assign({"zoom": 15 }, mapdata);

      window.d_reset = true;
      let params = "";
      Object.entries(mapdata).forEach(([k, v]) => {
        let val = $("<textarea.>").text(v).html(); //decode HTML
        if (val || val !== ",") {
          params += Discount.GetParam(k,val);
        }
      });
      MapHandler.EmbedMap($tgt, id, params);
    }
  },
  LoadAutoComplete : (inputId,refId,gkey) => {
    const input = document.getElementById(inputId);
    const mirror = document.getElementById(refId);
    input.focus();
    if (!window.autocomplete) {
      $.getScript("https://maps.googleapis.com/maps/api/js?key=" + gkey + "&libraries=places",() => {
        let currPos = mirror.getAttribute(Discount.Key.LocationDataLatLng).split(",");
        const loc = { latitude: parseFloat(currPos[0]), longitude: parseFloat(currPos[1]) };
        const options = {
          bounds: {
            north: loc.latitude + 0.1,
            south: loc.latitude - 0.1,
            east: loc.longitude + 0.1,
            west: loc.longitude - 0.1
          },
          fields: ["geometry"],
          types: ["(regions)"]
        };
        window.autocomplete = new google.maps.places.Autocomplete(input, options);

        //When value changed, update location value and close modal;
        google.maps.event.addListener(window.autocomplete, 'place_changed', (event) => {
          let place = window.autocomplete.getPlace();
          mirror.value = input.value;
          mirror.textContent = input.value;
          mirror.setAttribute(Discount.Key.LocationDataLatLng,place.geometry.location.lat() + "," + place.geometry.location.lng() + ",2");
          //mirror.setAttribute(Discount.Key.LocationDataLocationType,2)
          mirror.focus()

          $(".mfp-close").trigger("click");
        });
        //When autocomplete is cleared, clear location value;
        input.addEventListener("blur", () => {
          if (input.value === "") {
            //let loctype = Discount.GetDefaultLocation($("#" + inputId));
            mirror.value = loc.name;
            mirror.textContent = loc.name;
            mirror.focus();
          }
        })
      });
    }
  },
  SetAutocompleteDefault : ($trigger,$notification,location,_callback) => {
    //Location click => open autocomplete modal
    Discount.LoadAutoComplete(location.destination,location.origin, MapHandler.GetApiKey());

    //If location type is 3, change the link text
    let loctype = Discount.GetDefaultLocationType(location.input);
    if (loctype === 3) {
      $trigger.html(Discount.Key.AutoCompleteGeneralLocation);
    }
    if (loctype === 0) {
      $trigger.html(Discount.Key.AutoCompleteUnknownLocation); //select your location
    }

    //$(location.trigger).on("click",() => {});
    //Select current location, then close modal
    $trigger.on("click",()=> {
      loctype = 1;
      if($trigger.html() === Discount.Key.AutoCompleteGeneralLocation) {
        loctype = 3;
      }
      if($trigger.html() === Discount.Key.AutoCompleteUnknownLocation) {
        loctype = 0;
      }
      const replaceParam = (q,pk,pv) => {
        if (q.indexOf(pk) > 0) {
          q = q.replace(Discount.UrlEncode(Discount.GetParam(pk)),pv);
          if (!pv) {
            q = q.replace(pk+"=","");
            q = q.replace("&&","");
          }
        } else {
          q = q + Discount.GetParam(pk,pv);
        }
        return q;
      };

      let [path,query] = window.location.href.split('?');

      if (query) {
        query = replaceParam(query,"place","");
        query = replaceParam(query,"coord","");
        query = replaceParam(query,"loctype",loctype);
        history.replaceState("", "", path + "?"+ query);
      }
      //update location field (if location is disabled, grab IP location)
      console.log("Set for loctype: " + loctype);
      if (loctype === 1) {
        //Discount.SetBrowserLocation($notification,location.input);

        console.log("*****");
        console.log(window.locationPos);
      }
      let loc = Discount.GetLocationInfo(loctype);
      Discount.SetLocationField(location.input,loc);

      //update autocomplete value
      setTimeout(()=> {
        document.getElementById(location.destination).value = "";
      },10);

      //close modal
      location.modal.find(".mfp-close").trigger("click");

      //callback action for after close
      if (typeof _callback === "function") {
        _callback();
      }
    });
  },
  SetDiscountData : ($container, params,_callback) => {
    if (location.href.indexOf("localhost") >= 0) {
      Discount.RequestApiCall("http://localhost:" + window.location.port + "/json/sample-discount.json","GET",params,_callback);
    } else {
      Discount.RequestApiCall("https://" + window.location.hostname + "/api/discounts","POST",params,_callback);
    }
  },
  GetDiscountParams : (text,typeid,latlng,catid,sortid,distid,vendid,currpage,pagesize,stacked) => {
    const setObjectParam = (obj, key, val, typ, def) => {

      if (val) {
        if (typ === "string") {
          val = val.toString();
        }
        if (typ === "number") {
          val = isNaN(parseInt(val)) ? null : parseInt(val);
          val = val === 0 ? null : val;
        }
        return Object.assign({[key]: val}, obj);
      } else {
        if (typeof def !== "undefined") {
          return Object.assign({[key]: def}, obj);
        }
      }
      return obj;
    };
    let param = {};
    if (vendid) {
      param = setObjectParam(param,"nodeID",vendid,"number");
    } else {
      param = setObjectParam(param, "d_searchText",text,"string");
    }
    if (!latlng) {
      latlng = new Array("","")
    }
    param = setObjectParam(param,"d_TypeID",typeid,"number");
    param = setObjectParam(param,"d_CategoryIDs",catid,"array");
    param = setObjectParam(param,"d_sortID",sortid,"number");
    param = setObjectParam(param,"d_distanceID",distid,"number");
    param = setObjectParam(param,"Latitude",latlng[0],"string");
    param = setObjectParam(param,"Longitude",latlng[1],"string");
    param = setObjectParam(param,"pageNumber",currpage,"number",0);
    param = setObjectParam(param,"pageSize",pagesize,"number",100);
    param = setObjectParam(param,"discount_stacked",stacked,"boolean",false);
    return param;
  },
  GetJsonParams : (json) => {
    let params = {};
    for (const [key,obj] of Object.entries(json)) {

      //if value type isn't correct, convert
      if(obj.value && typeof obj.value !== obj.type) {
        obj.value = obj.type === "string" ? obj.value.toString() : obj.value;
        if (obj.type === "number") {
          obj.value = parseFloat(obj.value);
          obj.value = isNaN(obj.value) ? obj.value : null;
        }
      }
      //set default value if value is empty
      if (!obj.value && obj.default) {
        obj.value = obj.default;
      }
      params = obj.value || obj.value === obj.default ? Object.assign({[obj.key]:obj.value},params) : params;
    }
    return params;
  },
  getJsonParam : (k,v,t,d) => {
    if (!v) v = null;
    if (v && typeof v !== t) {
      switch (t) {
        case "string": v = v.toString(); break;
        case "number": v = parseInt(v); break;
      }
    }
    return { key: k, value: v, type:t, default:d }
  },
  RequestApiCall : (address,method,params,_callback) => {
    let options = {};
    let url = new URL(address);
    if (method === "POST") {//Post: send by JSON
      options = {
        method: method,
        headers : {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
      }

    }
    if (method === "GET") {//Get: create parameter

      for (const [k,v] of Object.entries(params)) {
        url.searchParams.set(k,v);
      }
      address = url;
      options = {
        method: method,
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin":"*"
        }
      }
    }
    fetch(address, options)
    .then(response => response.json())
    .then(data => {
      window.loadedData = data;
      _callback(data);
    })
    .catch((error) => {
    });
  },
  // RequestXMLCall : (address,method,params,_callback) => {
  //     let url = new URL(address);
  //     let xhr = new XMLHttpRequest();
  //     console.log("param is: ")
  //     if (method === "POST") {//Post: send by JSON
  //       console.log(params);
  //       xhr.open(method,url);
  //       xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
  //       xhr.responseType = "json";
  //       xhr.send(JSON.stringify(params));

  //     }
  //     if (method === "GET") {//Get: create parameter
  //       Object.keys(params).forEach(key => {
  //         console.log(key, params[key]);
  //         url.searchParams.set(key,params[key]);
  //       });
  //       xhr.open(method,url);
  //       //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
  //       //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

  //       xhr.responseType = "json";
  //       xhr.send();
  //     }
  //     xhr.onload = () => {
  //       _callback(xhr.response);
  //     };

  // },
  CheckBrowserLocation : ($note,$tgt,set) => {

    //This should be only occur for the first time
    window.locationSupport = false;
    let defer = $.Deferred();
    window.locationPos = "";
    let cook = Cookies.get(Discount.Key.LocationCookie);
    //let showWarning = window.sessionStorage.getItem("browserwarning") === "no" ? false : true;

    const checkSupport = () => {console.log("Checking browser support...");
      let df = $.Deferred();
      if (cook) {
        window.locationSupport = true;
        df.resolve();
        return false;
      }

      if (!iOS() || navigator.userAgent.indexOf("gonative") == -1 || geoDebug) {
        if ("geolocation" in navigator && navigator.permissions){
          navigator.permissions.query({ name: 'geolocation' }).then((permission)=>{
            let locGranted = false;

            switch(permission.state) {
              case "granted":
                //console.log("Location supported! (LS1) ");
                window.locationSupport = true;
                locGranted = true;
              break;
              case "denied":
                //console.log("Location denied (LS3) ");
                window.locationSupport = false;
                df.reject("error");
              break;
              case "prompt":
              default:
                window.locationSupport = true;
                df.resolve();
            }

            //If location is granted, grab the browser location
            if (window.locationSupport && locGranted) {
              navigator.geolocation.getCurrentPosition((pos) => {
                window.locationSupport = true;
                window.locationPos = pos;
                df.resolve();
              }, (err) => { //Fail
                //console.log("Location is not supported. (LE1)");
                window.locationSupport = false;
                df.reject(err);
              });
            }
          });
        } else {
          //if (window.locationSupport) {
          navigator.geolocation.getCurrentPosition((pos) => {
            window.locationSupport = true;
            window.locationPos = pos;
            df.resolve();
          }, (err) => { //Fail
            //console.log("Location is not supported. (LE1)");
            window.locationSupport = false;
            df.reject(err);
          });
        //}
        }
      } else {//console.log("Location is not supported. (LE2)");
        window.locationSupport = false;
        df.reject("browser doesn't support.");
      }
      return df.promise();
    };
    $.when(checkSupport()).then(()=> {//success

      //if set is true, set location
      //console.log("Set status: " + set);
      //If visible, add click event.
      $note.find("#use-my-location").on("click",() => {
        window.sessionStorage.setItem("discount","reload");console.log("session state is set to: " + window.sessionStorage.getItem("discount"));
        Discount.SetBrowserLocation($note,$tgt);
      });
      $note.find(".notification__close").on("click",() => {
        $note.addClass("hiding");
        setTimeout(() => {
          $note.removeClass("is-active");
          $note.removeClass("hiding");
        },500);
      });
      if (cook) {
        //if (set) {
          //If cookie already exists, set location
          cook = JSON.parse(cook);

          if (cook.browsersupport) {//no need to but just in case
            //Discount.SetBrowserLocation($note,$tgt);console.log("B2!")
            BaseHandler.Data.Set(Discount.Key.LocationCookie,JSON.stringify(cook),"1:day");
            defer.resolve();
            return false;
          }
        //}
      }

      //If cookie is not set, display location notification
        //2022-09-19: Daisuke
        //position exists, no need to display
      if(window.locationPos) {
        console.log("pos status: " + typeof window.locationPos.coords.latitude)
        if (typeof window.locationPos.coords.latitude == "number") {
          console.log("B1");
          if (!cook){
            let crd = window.locationPos.coords;
            BaseHandler.Data.Set(Discount.Key.LocationCookie,JSON.stringify({ latitude: crd.latitude, longitude: crd.longitude, loctype: 1, browsersupport: window.locationSupport}),"1:day");
          }
          defer.resolve();
          return false;
        }
      }
      //If location is not active, not support

      //window.locationSupport = false;
      $note.addClass("is-active");
      defer.resolve();
    },(err) => {//fail
      console.log(err)
      window.locationSupport = false;
      Discount.LocationWarning($note);
      defer.resolve(); //It goes in anyway
    });
    return defer.promise();
  },
  SetBrowserLocation : ($note,$tgt) => {
    if (!iOS() || navigator.userAgent.indexOf("gonative") == -1 || geoDebug) {
      if ("geolocation" in navigator && navigator.permissions) {
        navigator.geolocation.getCurrentPosition((pos) => { //Success

          window.locationSupport = true;
          let crd = pos.coords;
          BaseHandler.Data.Set(Discount.Key.LocationCookie,JSON.stringify({ latitude: crd.latitude, longitude: crd.longitude, loctype: 1, browsersupport: window.locationSupport}),"1:day");
          window.browserLocation = pos.coords;
          window.browserLocation["loctype"] = 1;
          $note.addClass("hiding");
          setTimeout(() => {
            $note.removeClass("is-active");
            $note.removeClass("hiding");
            let query = window.location.search;
            const qstrLoctype = "loctype=1"
            if (query) {
              let qlist = query.substring(1).split("&");
              query = "";
              qlist.forEach(q => {
                query += query ? "&" : "?";
                query += q.indexOf("loctype") >= 0 ? qstrLoctype : q;
              });
              if (query.indexOf("loctype") < 0) {
                query += "&" + qstrLoctype;
              }
            }
            //Add question mark if not there
            if (query.indexOf("?") === -1) {
              query = "?" + query;
            }
            if (window.sessionStorage.getItem("discount") === "reload") {
              window.sessionStorage.setItem("discount","reloaded");console.log("session state is set to: " + window.sessionStorage.getItem("discount"));
              window.location.href = window.location.href.split("?")[0] + query;
            }
          },500);
          Discount.SetLocationField($tgt,crd);
        }, (err) => { //Fail
          console.log(err)
          console.warn("Can't get location info from your browser.");
          window.locationSupport = false;
          Discount.LocationWarning($note);
          return false;
        });
      }
    }
  },
  LocationWarning : ($note) => {
    if (!window.locationSupport) {
      // let discountCookie = BaseHandler.Data.Get(Discount.Key.LocationCookie);
      // if (!discountCookie) {
      //   discountCookie = window.discountCookie;
      // }
      let showWarning = true;
      showWarning = window.sessionStorage.getItem("browserwarning") === "no" ? false : true;
      const hideWarning = () => {console.log($note)
        $note.addClass("hiding");
        setTimeout(() => {
          $note.removeClass("is-active");
          $note.removeClass("hiding");
        },500);
        //BaseHandler.Data.Set(Discount.Key.LocationCookie,JSON.stringify(discountCookie),"1:day");
      };
      if (showWarning) {
        
        let locationError = Discount.Key.LocationDeniedError + "<button class='notification__close'></button>";
        // let locationHost = encodeURIComponent("http://" + location.host + "/");
        // let settingUrl = "";
        // const browser = Bowser.getParser(window.navigator.userAgent); console.log(browser.getBrowser().name)
        // if (browser.getBrowser().name === "Edge") {
        //   settingUrl = "edge://settings/content/siteDetails?site=" + locationHost;
        // }
        // if (browser.getBrowser().name === "Chrome") {
        //   settingUrl = "chrome.tabs.create({url:'chrome://settings/content/location'});";
        // }
        // if (browser.getBrowser().name  === "Firefox") {
        //   settingUrl = "about:preferences#privacy";
        // }
        // if (settingUrl) {
        //   locationError = locationError.replace("[[","").replace("]]","");
        //   //locationError = locationError.replace("[[","<button class='notification__setting' onclick=\"" + settingUrl + "\">").replace("]]","</button>");
        // }
        
        $note.addClass("not-supported");
        locationError = locationError.replace("{{","").replace("}}","");
        $note.find(".notification").html(locationError);
        setTimeout(()=> {
          
          $note.addClass("is-active");
          //when X button is clicked, no browser warning till session ends
          $note.find(".notification__close").on("click",() => {
            hideWarning();
            window.sessionStorage.setItem("browserwarning","no");
          }); 
          
        },500);
      }
      //Hide warning 10 seconds later as well
      setTimeout(()=> {
        hideWarning();
      },100000);
    }
  },
  //Get LatLng info from input field. (a bit mix up with other function. May need to revisit and concrete the idea)
  GetLatLng : ($location) => {
    //If location isn't specified, get from cookie
    let coord;
    if(typeof $location !== "undefined") {
      let coord = $location.attr(Discount.Key.LocationDataLatLng);
      let loctype = 0;
      if (coord) {
        coord = coord.split(",");
        loctype = coord.length > 1 ? parseInt(coord[2]) : 0;
        return [coord[0],coord[1],loctype];
      }
    }
    //If location info isn't there, grab from location info so it always returns values
    if (!coord)  {
      const loc = Discount.GetLocationInfo();
      if (loc) {
        return [loc.latitude,loc.longitude,loc.loctype];
      }
    } else {
      return [,,];//return empty
    }
  },
  //Return JSON format
  GetLocationInput : ($location) => {
    if(typeof $location !== "undefined") {
      let coord = $location.attr(Discount.Key.LocationDataLatLng);
      if (coord) {
        coord = coord.indexOf(",") >= 0 ? coord.split(",") : "";
        if (coord.length > 2) {
          return {latitude:coord[0],longitude:coord[1],loctype:coord[2]};
        } else {
          return {latitude:coord[0],longitude:coord[1]};
        }
      }
    }
    return "";
  },
  //Function: GetLocationInfo
  //returns: object 1: latitue, 2: longitude, 3: location type
  //$location is only used for autocomplete. Not need to specify if you just grab
  GetLocationInfo : (loctype) => {
    
    //get location type not passed, try from query
    if (!loctype) {
      loctype = Discount.GetParam("loctype");
      let coord = Discount.GetParam("coord");
      if (coord && coord.indexOf(",") > 0) {
        coord = Discount.GetParam("coord").split(",");
        if (coord.length > 1) {
          loctype = coord[2];
        }
      }
      loctype = loctype && parseInt(loctype) ? parseInt(loctype) : 0;
    }
    console.log("loctype: " + loctype);
    if (loctype === 0) { //loctype not set
      //From Browser (autocomplete)
      if (Discount.GetParam("coord")) {
        
        //loctype has a value and autocomplete type
        //This doesn't make sense to have within loctype 0
        if (loctype === 2) {
         
        }
      }
      //Cookie discount data
      let loc = Cookies.get(Discount.Key.LocationCookie);
      if (typeof loc === "string" && loc) {
        if (loc.latitude) {
          loc = JSON.parse(loc);
          loc["loctype"] = 1;
          return loc;
        }
      }
      
      //Determine browser coords info
      if (window.locationSupport) {
        return window.browserLocation;
      }
      //Determine API coords info
      const geoInfo = BaseHandler.Geo.GetGeoInfo();
      if (geoInfo) {
        geoInfo["loctype"] = 3;
        return geoInfo;
      }
    }
    if (loctype === 1) { //current
      let loc = Cookies.get(Discount.Key.LocationCookie);
      if (!loc && window.locationPos) {
        let lat = window.locationPos.coords.latitude, lng = window.locationPos.coords.longitude;
        console.log(lat +":"+ lng);
        loc = {
          latitude: lat,
          longitude: lng,
          loctype:1
        }
      }console.log(window.locationPos)
      if (loc) {
        if (typeof loc === "string") {
          loc = JSON.parse(loc);
          
        } 
        loc["loctype"] = 1;
        console.log("[Location info]");console.log(loc);
        return loc;
      } else {
        //if location doesn't exists in cookie or pos variable, it should get IP location
        loctype = 3;
      }
      
    }
    if (loctype === 2) {//autocomplete
      if (typeof $location === "object") {
        Discount.GetLocationInput();
      }
      //This shouldn't never get in here;;;
      if (Discount.GetParam("coord")) {
        let coord = Discount.GetParam("coord") ? Discount.GetParam("coord").split(",") : [,,];
        if (coord[2] && coord[2] === "2") {//loctype has a value and autocomplete type
          return {latitude:coord[0],longitude:coord[1],loctype:coord[2]};
        }
      }
    }
    if (loctype === 3) {//geo
      const geoInfo = BaseHandler.Geo.GetGeoInfo();
      if (geoInfo) {
        geoInfo["loctype"] = 3;
        return geoInfo;
      }
    }
    // //If both type couldn't return, last resource
    // //Determine from Base
    // console.log("Cannot retrieve location info. Trying base info.");
    // let base = BaseHandler.Data.Get(BaseHandler.Key.Base);
    // if (base) {console.log("geting base by guid")
    // BaseHandler.Geo.GetBaseByGuid(base,() => {
    //     let cnt = 0;
    //     const timer = setInterval(()=> {
    //       if (window.basestatus === "found") {
    //         window.baseInfo["loctype"] = 4;
    //         window.basestatus = "ready";
    //         console.log(window.baseInfo)
    //         clearInterval(timer);
    //         return window.baseInfo;
    //       }
    //       cnt > 3 ? clearInterval(timer) : cnt ++;
    //     }, 100);
    //   });
    // } else {//No base info then show national
    //   console.log("No base found. returning national base.")
    //   return BaseHandler.Geo.GetNationalBase();
    // }
    
    //Any other types, grab from base info
    // if (window.baseInfo) {
    //   window.baseInfo["loctype"] = 4;
    //   return window.baseInfo;
    // } else {
    //   let base = BaseHandler.Data.Get(BaseHandler.Key.Base);
    //   if (base) {
    //     return BaseHandler.Geo.GetBaseByGuid(base,(baseinfo) => {
    //       baseinfo["loctype"] = 4;
    //       return baseinfo;
    //     });
    //   }
    // }
    
    
  },
  //Used at autocoomplete to fill name. this could be merged into GetLocationInfo()
  GetDefaultLocation : ($location) => {
    let loctype = 0;console.log(window.locationPos)
    if (window.locationPos) {
      loctype = 1;
    } else {
      loctype = Discount.GetDefaultLocationType($location);
    }
    let loc = Discount.GetLocationInfo(loctype);
    if (loc.loctype === 1) {
      loc["name"] = Discount.Key.LocationUseCurrent;
    }
    // if (loc.loctype === 2) {
      
    // }
    if (loc.loctype === 3) {
      loc["name"] = Discount.Key.LocationUseGeo;
    }
    if (!loc.loctype || loc.loctype === 4) {
      loc["name"] = Discount.Key.LocationUnknown;
    }
    return loc;
  },
  GetDefaultLocationType : ($location) => {
    //If querystring exists, 
    let loctype = Discount.GetParam("loctype");
    
    if (loctype) {
      loctype = parseInt(loctype);
    }
    if (Discount.GetParam("coord")) {
      loctype = 2;
    }
    
    //Check if input field location type exists
    let fldloc = $location.attr(Discount.Key.LocationDataLatLng);
    if (fldloc) {
      fldloc = fldloc.indexOf(",") > 0 ? fldloc.split(",") : "";
      let fldloctype = (fldloc.length > 2) ? parseInt(fldloc[2]) : 0;
      if (!loctype && fldloctype > 0) {
        console.log("Use input loctype.");
        loctype = fldloctype;
      }
      if (loctype !== fldloctype) {
        console.log("Overwriting the input loctype with query string one.");
        //Discount.SetLocationField($location);
      }

    }
    //If both doesn't exists, get from the browser
    if (!loctype) {console.log("Location type couldn't find yet")
      if (window.locationSupport) {
        loctype = Cookies.get(Discount.Key.LocationCookie) || window.browserLocation ? 1 : 3;
      } else { //No browser support
        loctype = 3;
      }
    }
    //if location support is off but loctype is selected to 1, overwrite
    if (loctype === 1 && !window.locationSupport) {
      loctype = 3;
      //if location support isn't enabled yet and if it came from querystring, remove querystring for confusions
      if (Discount.GetParam("loctype") == 1) {
        let locurl = window.location.href;
        locurl = locurl.replace("loctype=1","").replace("&&","").replace("?&","");
        locurl = locurl.charAt(locurl.length - 1) === '?' ? locurl.substring(0,locurl.length - 1) : locurl;
        history.replaceState("", "", locurl);
      }
    }
    console.log("Default Loctype: " + loctype);
    return loctype;
  },
  SetLocationField : ($location,loc) => {
    //If location info isn't sent, grab from querystring
    if (typeof loc === "undefined") {
      // let coords = Discount.GetParam("location").indexOf(",") >= 0 ? Discount.GetParam("location").split(",") : ["","",0];
    }
    if (loc.loctype) {
      //$location.attr(Discount.Key.LocationDataLocationType,loctype); 
      switch(parseInt(loc.loctype)) {
        case 1: //current
          $location.attr(Discount.Key.LocationDataLatLng,loc.latitude+","+loc.longitude+"," + loc.loctype);
          navigator.geolocation.getCurrentPosition(showPosition,fallbackPosition);

        break;
        case 2: //Autocomplete
          $location.val(Discount.GetParam("place"));
          $location.attr(Discount.Key.LocationDataLatLng,loc.latitude+","+loc.longitude+"," + loc.loctype);
        break;
        case 3: //From Geocode
          $location.attr(Discount.Key.LocationDataLatLng,loc.latitude+","+loc.longitude+"," + loc.loctype);
          $location.val(window.geoInfo.city+", "+window.geoInfo.region_code);
          // if (window.locationSupport) {
          //   //$location.val(loc.city + ", " + loc.region_code);
          //   $location.val(Discount.Key.LocationUseGeo);
          // }
        break;
        case 4: //From Base cookie
        //   $location.attr(Discount.Key.LocationDataLatLng,loc.latitude+","+loc.longitude+"," + loc.loctype);
        //   $location.val(Discount.Key.LocationUseBase);
        
        //   // BaseHandler.Geo.GetBaseByGuid(BaseHandler.Data.Get(BaseHandler.Data.Base),(base) => {console.log("setting up base");
        //   //   $location.attr(Discount.Key.LocationDataLatLng,base.latitude+","+base.longitude+"," + loc.loctype);
        //   // });
        // break;
        default: //From Base cookie
          //$location.attr(Discount.Key.LocationDataLatLng,loc.latitude+","+loc.longitude+"," + loc.loctype);
          $location.val(Discount.Key.LocationUnknown);
      }
    }
    //  else { //Set empty
    //   $location.val("");
    //   $location.attr(Discount.Key.LocationDataLatLng,"");
    //   //$location.attr(Discount.Key.LocationDataLocationType,"");
    // }
    
  },
  GetLocationParam : ($loc) => {
    let loc = $loc.attr(Discount.Key.LocationDataLatLng);
    let loctype = 0;
    if (loc) {
      loc = loc.split(",");
      loctype = loc.length && parseInt(loc[2]) ? parseInt(loc[2]) : 0;
    }
    //only current location & autocomplete needs to return value
    if (loctype) {
      let locp = "";
      //only autocomplete needs place value
      if (loctype === 2) {
        locp = Discount.GetParam("place",$loc.val()) +
               Discount.GetParam("coord",$loc.attr(Discount.Key.LocationDataLatLng));
      } else {
        locp = Discount.GetParam("loctype",loctype);
      }
      return locp;
    }
    return "";
  },
  UrlEncode : (txt) => {
    if(txt) {
      let enc = encodeURIComponent(txt);
      enc = enc.replaceAll("%2C",","); //No comma to be encoded
      enc = enc.replaceAll("%20","+"); //change space to plus
      txt = enc;
    }
    return txt;
  },
  UrlDecode : (txt) => {
    txt = txt.replaceAll(",","%2C"); //Replace back comma to encode standard
    let dec = decodeURIComponent(txt);
    dec = dec.replaceAll("+"," "); //change plus to space
    return dec;
  },
  GetParam : (param,val) => {
    
    //If val not specified, return value
    if (typeof val === "undefined") {
      const query = location.search;
      val = "";
      if (query) {
        let qlist = query.substring(1).split("&");
        qlist.forEach(q => {
          let list =  q.split("=");
          if (list[0] === param) {
            val = Discount.UrlDecode(list[1]);
          }
        })
      }
      return val;
    }
    //If val specified, return param string
    //before the statement, set reset value for conjunction of the query string
    if (val && parseInt(val) !== 0) { //if value is 0, don't pass
      const bond = window.d_reset ? "?" : "&"; window.d_reset = false;
      return val ? bond + param + "=" + Discount.UrlEncode(val) : "";
    }
    return "";
  },
  SortBy : (data,sortnum) => {
    /*if (sortnum == 2) { //Distance
      console.log("distance sort");
      data.sort((a, b) => {
        return parseFloat(a.discount_distance) - parseFloat(b.discount_distance)});
    } else { //Latest
      console.log("latest sort");
      data.sort((a, b) => {
        return new Date(b.discount_updated) - new Date(a.discount_updated)
      });
    }*/
    return data;
  },
  CalculateToteDistances : ($toteDist,precise,$toteBox) => {
    typeof precise === "undefined" ? false : true;
    const goCalculateToteDistance = (loc) => {
      $toteDist.each((k,dist) => {
        let latlng = $(dist).attr(Discount.Key.LocationDataLatLng);
        let distance = 0, dispdist = "";
        if (latlng && latlng.indexOf(",") > 0) {
          latlng = latlng.split(",");
          distance = BaseHandler.Calculate.Distance(loc.latitude,loc.longitude,latlng[0],latlng[1]);
          if (distance > 1000 && !precise) { 
            dispdist = distance === Discount.Key.DistanceMax ? "N/A" : Discount.Key.ToteDistance1000;
          } else {
            dispdist = parseFloat(distance).toFixed(1) + " KM"
            if (distance > 100) dispdist = parseInt(distance) + " KM";
            if (distance < 1  && !precise) dispdist = Discount.Key.ToteDistanceLessThan1;
          }
          $(dist).text(dispdist);
          $(dist).attr(Discount.Key.LocationDataToteDistance,parseFloat(distance)); //distance data attribute (it is used for sorting and not for calculation)
        } else {
          //$(dist).text("N/A");
          $(dist).hide();
          $toteDist.attr(Discount.Key.LocationDataToteDistance,"");
        }
        //if the totebox exists and distance isn't calculated, hide it.
        if (typeof $toteBox === "object") {
          //distance is max or not calculated, hide the box;
          if (distance === Discount.Key.DistanceMax || distance === 0) {
            $toteBox.hide();
          }
        }
      });
    }
    
    const ProcessByLocationType = () => {
      let defT = $.Deferred();
      let loctype = Discount.GetParam("loctype");
      
      loctype = loctype ? parseInt(loctype) : 0;
      let loc = Discount.GetLocationInfo(loctype);
      if (!loc) {
        defT.reject();
      } else {
        defT.resolve(loc);
      }
      return defT.promise();
    };
    $.when(ProcessByLocationType()).then((loc) => {
      goCalculateToteDistance (loc);
    },() => {
      $toteDist.text("N/A");
    });
  },
  CalculateDistance : (data, coord, limit) => {
    let sdata = [];
    if (!coord[0]) {
      coord = Discount.GetLatLng();
    }
    if(coord[0]){
      limit = isNaN(parseInt(limit)) ? 0 : parseInt(limit);
      $.each(data,(key, item)=> {
        let distance = parseFloat(BaseHandler.Calculate.Distance(coord[0],coord[1],item.discount_latitude,item.discount_longitude));
        if (limit === 0 || distance <= limit) {
          item["discount_distance"] = distance;
          sdata.push(item);
        }
        //console.log(coord[0]+":"+coord[1]+":"+item.discount_latitude+":"+item.discount_longitude);
      });
      return sdata;
    } else {
      console.warn("cannot determine the location info." + coord[0]);
      return data;
    }
  },
  FormatDistance : ($item) => {
    $item.each((k, v) => {
      let distance = parseFloat($(v).attr(Discount.Key.LocationDataToteDistance));
      if (distance > 1000) { 
        if (distance === Discount.Key.DistanceMax) {
          //$(v).hide();
          distance = "N/A";
        } else {
          distance = Discount.Key.ToteDistance1000;
        }
      } else {
        if (distance > 100) distance = parseInt(distance) + " KM";
        else distance = parseFloat(distance).toFixed(1) + " KM";
        if (distance < 1) distance = Discount.Key.ToteDistanceLessThan1;
      }
      $(v).text(distance);
    });
  },
  //Adding location string in the links if querystring is passed
  //param 1: link target
  SetLocationLinkParams : ($links) => {
    //Adding G-location link
    let place = "place", coord = "coord";
    if (Discount.GetParam(place)) {
      window.d_reset = false;
      let loc = Discount.GetParam(place,Discount.GetParam(place)) + 
        Discount.GetParam(coord,Discount.GetParam(coord));
        loc = loc.substring(1);
      $links.find("a").each((k,v)=> {
        let connector = $(v).attr("href").indexOf("?") >= 0 ? "&" : "?";
        $(v).on("click",()=>{
          location.href = $(v).attr("href") + connector + loc;
          return false;
        });
      });
    }
  },
  Test : {
    Coord : (lat,lng) => {
      console.log("https://www.google.com/maps/@" + lat + "," + lng + ",15z");
    }
  }
};

function iOS() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1;
}

function showPosition(position) {
  $.getScript("https://maps.googleapis.com/maps/api/js?key=" + MapHandler.GetApiKey() + "&libraries=places", function() {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    //alert("Latitude: " + lat + "<br>Longitude: " + lon);
    
    // Use Google Maps Geocoding API to get city name
    let geocoder = new google.maps.Geocoder();
    let latlng = { lat: parseFloat(lat), lng: parseFloat(lon) };

    //var geoData = BaseHandler.Geo.GetGeoInfo();
    geocoder.geocode({ 'location': latlng }, function(results, status) {
        if (status === 'OK') {
            if (results[0]) {
                let city = results[0].address_components.find(component => 
                    component.types.includes("locality")
                ).long_name;
                let province = results[0].address_components.find(component =>
                  component.types.includes("administrative_area_level_1")
                ).short_name;
                $("#discountFilter_location").val(city+", "+province);
            } else {
              $("#discountFilter_location").val(window.geoInfo.city+", "+window.geoInfo.region_code);
            }
        } else {
          $("#discountFilter_location").val(window.geoInfo.city+", "+window.geoInfo.region_code);
        }
    });
  }).fail(function(jqxhr, settings, exception) {
     $("#discountFilter_location").val(window.geoInfo.city+", "+window.geoInfo.region_code);
  });
}

function fallbackPosition(error){
  $("#discountFilter_location").val(window.geoInfo.city+", "+window.geoInfo.region_code);
}