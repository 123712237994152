import { Discount } from './modules/discounts.js';

const filterLocation = {//Filter location container
  "input":$("#discountFilter_location"),
  "trigger":$(".discount-filter__location"),
  "origin":"discountFilter_location",
  "destination":"discountFilter_places",
  "modal":$(".modal--location")
}; 

$.when(Discount.CheckBrowserLocation($(".notification--location__container"), filterLocation.input, true)).then(() => {

  //Init discount filter
  Discount.InitDiscountFilter (
    $("#discountFilter_keyword"),
    $(".discount-filter__type select"),
    filterLocation,
    $(".discount-filter__dropdown input[name=selectedOptions]"),
    $(".discount-filter__search"),
    ($keyword, $type, $location, $cats, $action) => {
      window.d_reset = true;
      var catList = "";
      $cats.each( function() {
        if($(this).is(":checked")){
          catList += Discount.GetParam("category",$(this).val());
        }
      });
      window.location.href = $action.data("location") + 
        catList +
        Discount.GetParam("keyword",$keyword.val()) + 
        Discount.GetParam("type",$type.val()) +
        Discount.GetLocationParam($location);
    }
  );
  
  //Set autocomplete
  Discount.SetAutocompleteDefault(filterLocation.modal.find(".select-current"),$(".notification--location__container"),filterLocation);
  
  //Set location parameters
  Discount.SetLocationLinkParams($(".breadcrumbs"));
});

  
const resizeFont = ($text,minF,maxW) => {
  if ($text.length > 0) {
    let fontsize = parseInt($text.css('fontSize').replace("px",""));

    //console.log("width is: " + $text.width());
    if(isNaN(fontsize) || fontsize <= minF) {
      return;
    }
    if ($text.width() > maxW) {
      
      $text.css('fontSize', parseFloat(fontsize) - 1);
      resizeFont($text,maxW,minF);
    }
  }
};
const minFont = 16;
resizeFont($(".sidebar-tote .discount"),minFont,275);
$(".discount-tote .discount-tote__percent").each((k,v) => {
  resizeFont($(v),minFont,230);
});
